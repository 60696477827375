import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers'

function getPreloadedState(reduxVersion) { 
    return {
        drinks: [],
        fetchDrinks: [],
        userSettings: {
            weight: '',
            startingDriver: '',
            gender: '',
            driverLicense: true
        },
        advice: {
            drivingAllowed: true
        },
        config: {
            startTime: ''
        },
        reduxVersion: reduxVersion,
        validTo: new Date().getTime() + 18 * 3600000,
        country: window.location.hostname.endsWith(".be") ? "be" : "nl"
    }
};

function loadState() {
    try {
        const serializedState = localStorage.getItem("reduxstate");
        const reduxVersion = 4;
        var preloadedState = getPreloadedState(reduxVersion);
        if (!serializedState) {
            return preloadedState;
        }

        let deSerializedState = JSON.parse(serializedState);
        if (deSerializedState.reduxVersion !== reduxVersion) {
            localStorage.removeItem("reduxstate");
            return preloadedState;
        }
        if (deSerializedState.validTo < new Date().getTime())
        {
            deSerializedState.drinks = preloadedState.drinks;
            deSerializedState.advice = preloadedState.advice;
            deSerializedState.config = process.config;
        }

        

        return deSerializedState;
    } catch (err) {
        return getPreloadedState();
    }
};

function saveState(state) {
    try {
        state.validTo = new Date().getTime() + 18 * 3600000;
        const serializedState = JSON.stringify(state);
        localStorage.setItem("reduxstate", serializedState);
    } catch (err) {
        console.log(err);
    }
};

export default function Store() {
    var reduxstore = createStore(
        rootReducer, loadState(),
        compose(
            applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );

    reduxstore.subscribe(() => {
        saveState(reduxstore.getState());
    });

    return reduxstore;
}