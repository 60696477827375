import React from 'react'
import logo from '../logo.png';
import '../styles/animate.css';
import '../styles/bootstrap.css';
import '../styles/colors.css';
import '../styles/custom.css';
import '../styles/dark.css';
import '../styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { hotjar } from 'react-hotjar';


class Header extends React.Component {
    initializeReactGA() {
        ReactGA.initialize('UA-171353933-1');
        ReactGA.pageview(window.location.pathname);
    }

    initializeHotjar(){
        hotjar.initialize('2596395', '6');
    }

    render() {
        this.initializeReactGA();
        this.initializeHotjar();

        return (
            <header id="header" className="page-section">
                <div id="header-wrap">
                    <div className="container">
                        <div className="header-row">
                            <div id="logo">
                                <a href="/">
                                    <img alt="logo" src={logo} style={{ height: 76, marginTop: 15, display: 'initial' }} />
                                </a>
                            </div>

                            <div className="header-misc-icon d-sm-block">
                                <a href="/profiel"><FontAwesomeIcon icon={faUser} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-wrap-clone" style={{ height: 100 }}></div>
            </header >
        )
    }
}

function mapStateToProps() {   
    return {
        
    }
}

function mapDispatchToProps(dispatch) {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);