import { combineReducers } from 'redux'
import drinks from './drinks'
import userSettings from './userSettings'
import advice from './advice'
import config from './config'
import reduxVersion from './reduxVersion'
import country from './country'
import validTo from './validTo'
import fetchDrinks from './fetchDrinks'

export default combineReducers({
  drinks,
  userSettings,
  advice,
  config,
  reduxVersion,
  country,
  validTo,
  fetchDrinks
})