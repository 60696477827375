function drinks(state = [], action) {
  switch (action.type) {
    case 'ADD_DRINK':
      var addedDrink = {
        id: action.id,
        name: action.name,
        percentage: action.percentage,
        unitId: action.unitId,
        capacity: action.capacity,
        numberOfUnits: action.numberOfUnits,
        category: action.category
      };

      return [
        ...state,
        addedDrink
      ]

    case 'DELETE_DRINK':
      return state.drinks = state.filter((item, index) => item.id !== action.id)

    case 'INCREASE_NUMBER_OF_UNITS':
      return state.map((drink, index) => {
        if (drink.id !== action.id) {
          // This isn't the item we care about - keep it as-is
          return drink
        }

        var updatedDrink = drink;
        updatedDrink.numberOfUnits++;
    
        // Otherwise, this is the one we want - return an updated value
        return {
          ...drink,
          updatedDrink
        }
      })

    case 'DECREASE_NUMBER_OF_UNITS':
      return state.map((drink, index) => {
        if (drink.id !== action.id) {
          // This isn't the item we care about - keep it as-is
          return drink
        }

        var updatedDrink = drink;
        updatedDrink.numberOfUnits--;
    
        // Otherwise, this is the one we want - return an updated value
        return {
          ...drink,
          updatedDrink
        }
      })

    default:
      return state
  }
}

export default drinks