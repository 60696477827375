function advice (state = "", action) {
  switch (action.type) {
      case 'ADD_ADVICE':        
        return {
            drivingAllowed: action.drivingAllowed
          }
      default:
        return state
    }
  }
  
  export default advice