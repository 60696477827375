function fetchDrinks(state = [], action) {
  switch (action.type) {
    case 'FETCH_DRINKS':
      return {
        drinks: action.fetchDrinks
      }
    default:
      return state
  }
}

export default fetchDrinks