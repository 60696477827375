function userSettings (state = "", action) {
  switch (action.type) {
    case 'ADD_USER_SETTINGS':

      return {
        weight: action.weight,
        startingDriver: action.startingDriver,
        gender: action.gender,
        driverLicense: action.driverLicense
      }
    default:
      return state
  }
}

export default userSettings