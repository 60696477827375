function config (state = "", action) {
  switch (action.type) {
    case 'ADD_CONFIG':

      return {
        startTime: action.startTime
      }
    default:
      return state
  }
}

export default config